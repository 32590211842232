<template>
    <div class="mainwindow">
        <!-- step 1 -->
        <div class="verifyForm" v-if="step==1">
            <div class="formheader" >
                {{step1Title}}
            </div>
            <div class="emptyline"></div>
            <el-form ref="verifyForm"
                     :model="form"
                     label-width="140px"
                     :rules="verifyRules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="inputitem" :label="countryLabel" prop="country">
                            <el-select class="countrySelect" filterable :filter-method="countryFilter" v-model="form.country" @change="onCountryChange"
                                       :placeholder="countryPlaceholder">
                                <el-option v-for="item in countryDatas"
                                        :key="item.countryCode"
                                        :label="item.countryName"
                                        :value="item.countryCode">
                                </el-option>
                            </el-select>
                        </el-form-item>

                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item class="inputitem-two" :label="phoneAreaLabel"
                                  prop="phoneArea">
                        <el-col :span="7">
                            <el-select class="phoneAreaSelect" filterable :filter-method="areaFilter" v-model="form.country" @change="onCountryChange"
                                       :placeholder="phoneAreaPlaceholder">
                                <el-option
                                        v-for="item in phoneAreas"
                                        :key="item.countryCode"
                                        :label="item.areaCode"
                                        :value="item.countryCode">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="1">
                        </el-col>
                        <el-col :span="16">
                            <el-input class="phone-input"  v-model="form.phone" :placeholder="phonePlaceholder"></el-input>
                        </el-col>
                    </el-form-item>

                </el-row>
                <el-button type="primary" class="step1-button"
                           @click.native="doSendCode" :disabled="sendCodeDisabled">{{step1ButtonText}}</el-button>
                <div class="step1-bottom">
                    <div class="bottomnotice">{{noticeText}}</div>
                </div>
            </el-form>
        </div>
        <!-- step 2 -->
        <div class="verifyForm" v-if="step==2">
            <div class="formheader" >
                {{step2Title}}
            </div>
            <div class="emptyline"></div>
            <div class="verifyline">
                <div class="icon" ></div>
                <div class="content">
                    <div class="phoneline">
                        <div class="phoneitem">{{phoneLabel}}</div>
                        <div class="phoneitem" style="margin-left: 12px">{{form.phoneArea}}-{{form.phone}}</div>
                    </div>

                    <div class="nodity">{{phoneNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <div class="emptyline"></div>
            <el-form ref="authForm"
                     :model="form"
                     label-width="140px"
                     :rules="authRules">
                <div class="verifycodebox" prop="phone">
                    <div class="phoneicon"></div>

                    <el-form-item class="authencodebox"
                                      prop="authcode">
                        <el-input  v-model="authcode" :placeholder="authcodePlaceholder"></el-input>
                        <div class="resend" @click="doResend()">{{resendText}}</div>
                    </el-form-item>

                </div>
            </el-form>
            <el-button type="primary" class="step2-button" :disabled="submitDisabled" @click.native="doSubmit">{{step2ButtonText}}</el-button>

        </div>
        <!-- step 3 -->
        <div class="verifyForm" v-if="step==3">
            <div class="formheader" >
            </div>
            <div class="succesbox">
                <div class="success" ></div>
            </div>
            <div class="successinfobox">
                <div class="successinfo">{{successinfo}}</div>
            </div>

            <div class="bottom">
                <router-link to="/login" replace class="link">{{signinText}}</router-link>
            </div>
        </div>
    </div>

</template>

<script>
    import utils from "../../../util/utils";

    var _self;
    const _module='CTMobileverify';
    const _common="ctError";
    // const checkCountryRule=(rule,value,callback)=>{
    //     _self.checkCountry(rule,value,callback);
    // }
    const checkPhoneAreaRule=(rule,value,callback)=>{
        _self.checkPhoneArea(rule,value,callback);
    }
    const checkAuthCodeRule=(rule,value,callback)=>{
        _self.checkAuthCode(rule,value,callback);
    }
    export default {
        name: 'login',
        components: {
        },
        data() {
            return {
                // step 1
                step1Title: 'Set up two-step verication',
                countryLabel: 'Country:',
                countryPlaceholder: 'Country',
                phoneAreaLabel: 'Phone:',
                phoneAreaPlaceholder: '+8888',
                phonePlaceholder: 'Phone Number',
                step1ButtonText: 'Send Code',
                noticeText: 'Security is critical at CIC. To keep your account safe, please provide your mobile number to receive authentication messages or specific notifications.',

                // step 2
                step2Title: "Enter authentication code",
                phoneLabel: "Mobile:",
                phoneNotifyContent: 'A comfirm message has been send to your phone',
                clickinfo:"Please enter the verify code",
                authcodePlaceholder:"Please enter the verify code",
                step2ButtonText:"Submit",

                // step 3
                successinfo:"Registration successful",
                signinText:"Sign in >",
                // 数据校验
                countryIsNull:"Please select a country",
                phoneAreaIsNull:"Please select a area",
                phoneIsNull:"Phone can not null",
                authCodeIsNull:"Auth code is null",
                phoneIsTooLong:"电话超过15位",
                // 数据
                emailKey:'',
                codeKey:'',
                step: 1,

                phneContent: '  +86-13800138000',
                authcode:'',
                defaultResendText:'重新发送',
                resendText:'重新发送',
                lastTime :60,
                countryDatas:[],
                countryDatasDefault:[],
                phoneAreas:[],
                form: {
                    country: '',
                    phoneArea: '',
                    phone: ""
                },
                verifyRules:{
                    country:[
                        { required: true, message: this.$t('CTMobileverify.countryIsNull'), trigger: 'change' }
                    ],
                    // country:[ {validator:checkCountryRule,trigger:'blur'}],
                    // phoneArea:[{validator:checkPhoneAreaRule,trigger:'blur'}]
                    phoneArea:[
                        { required: true, message: this.countryIsNull,validator:checkPhoneAreaRule, trigger: 'blur' }
                    ],
                },
                authRules: {
                    authcode:[{validator:checkAuthCodeRule,trigger:'blur'}]
                },

                timer :null,
              submitDisabled:false,
              sendCodeDisabled:false,
            }
        },
        computed:{

        },
        mounted(){
            if (!utils.isPC()){
                this.$router.push({
                    path: '/mobileverifyMobile',
                    query: this.$route.query
                })
                return
            }

            _self = this;
            this.emailKey = this.$route.query.key;

            this.axios.post('/countryListTrade', {}).then(ret=>{
                if(ret.status === 1){
                    _self.countryDatas = ret.data;
                    _self.countryDatasDefault = this.countryDatas.filter(() => {
                        return true;
                    })
                    var result = [];
                    result.push(ret.data[0]);
                    for(var i=1;i<ret.data.length;i++){
                        var find = false;
                        for(var j=0;j<result.length;j++){
                            if(result[j].areaCode > ret.data[i].areaCode){
                                find = true;
                                result.splice(j,0,ret.data[i]);
                                break;
                            }
                        }
                        if(!find){
                            result.push(ret.data[i]);
                        }
                    }

                    _self.phoneAreas = result;
                    // _self.step = 2;
                } else {
                    _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                }
                if(_self.$showLog)
                console.log(ret);
            }).catch(err=>{
                if(_self.$showLog)
                console.log(err);
            })

            this.step1Title = this.$t(_module+'.step1Title');
            this.countryLabel = this.$t(_module+'.countryLabel');
            this.countryPlaceholder = this.$t(_module+'.countryPlaceholder');
            this.phoneAreaLabel = this.$t(_module+'.phoneAreaLabel');
            this.phoneAreaPlaceholder = this.$t(_module+'.phoneAreaPlaceholder');
            this.phonePlaceholder = this.$t(_module+'.phonePlaceholder');
            this.step1ButtonText = this.$t(_module+'.step1ButtonText');
            this.noticeText = this.$t(_module+'.noticeText');
            this.step2Title = this.$t(_module+'.step2Title');
            this.phoneLabel = this.$t(_module+'.phoneLabel');
            this.phoneNotifyContent = this.$t(_module+'.phoneNotifyContent');
            this.clickinfo = this.$t(_module+'.clickinfo');
            this.authcodePlaceholder = this.$t(_module+'.authcodePlaceholder');
            this.step2ButtonText = this.$t(_module+'.step2ButtonText');
            this.successinfo = this.$t(_module+'.successinfo');
            this.signinText = this.$t(_module+'.signinText');
            this.countryIsNull = this.$t(_module+'.countryIsNull');
            this.phoneAreaIsNull = this.$t(_module+'.phoneAreaIsNull');
            this.phoneIsNull = this.$t(_module+'.phoneIsNull');
            this.authCodeIsNull = this.$t(_module+'.authCodeIsNull');
            this.phoneIsTooLong = this.$t(_module+'.phoneIsTooLong');
            this.defaultResendText = this.$t('CTResetPass.defaultResendText');
            this.resendText = this.$t('CTResetPass.defaultResendText');


        },

        methods: {
            countryFilter(val) {
                this.value = val;
                if (val) { //val存在
                    this.countryDatas = this.countryDatasDefault.filter((item) => {
                        if (!!~item.countryName.indexOf(val) || !!~item.countryName.toUpperCase().indexOf(val.toUpperCase())) {
                            return true
                        }
                    })
                } else { //val为空时，还原数组
                    this.countryDatas = this.countryDatasDefault;
                }
            },
            areaFilter(val) {
                this.value = val;
                var  areas=[];
                if (val) { //val存在
                     areas = this.countryDatasDefault.filter((item) => {
                        if (!!~item.areaCode.indexOf(val) || !!~item.areaCode.toUpperCase().indexOf(val.toUpperCase())) {
                            return true
                        }
                    })
                } else { //val为空时，还原数组
                    areas = this.countryDatasDefault;
                }

                var result = [];
                if(areas.length >0){
                    result.push(areas[0]);
                    for(var i=1;i<areas.length;i++){
                        var find = false;
                        for(var j=0;j<result.length;j++){
                            if(result[j].areaCode > areas[i].areaCode){
                                find = true;
                                result.splice(j,0,areas[i]);
                                break;
                            }
                        }
                        if(!find){
                            result.push(areas[i]);
                        }
                    }
                }


                this.phoneAreas = result;
            },

            onCountryChange(value){
                //debugger;
                if(_self.$showLog)
                console.log(value);
                {
                    for(var i=0;i<this.countryDatasDefault.length;i++){
                        if(this.countryDatasDefault[i].countryCode == value){
                            this.form.phoneArea = this.countryDatasDefault[i].areaCode;
                        }
                    }
                    if(_self.$showLog)
                        console.log(value + this.form.phoneArea);
                    _self.value = "";
                    _self.countryDatas = [];
                    _self.countryDatas = _self.countryDatasDefault.filter(() => {
                        return true;
                    })
                }
                {
                    var result = [];
                    if(_self.countryDatasDefault.length >0){
                        result.push(_self.countryDatasDefault[0]);
                        for(var i0=1;i0<_self.countryDatasDefault.length;i0++){
                            var find = false;
                            for(var j=0;j<result.length;j++){
                                if(result[j].areaCode > _self.countryDatasDefault[i0].areaCode){
                                    find = true;
                                    result.splice(j,0,_self.countryDatasDefault[i0]);
                                    break;
                                }
                            }
                            if(!find){
                                result.push(_self.countryDatasDefault[i0]);
                            }
                        }
                    }

                    _self.phoneAreas= result;
                }


            },
            checkCountry (rule,value,callback){
                if(value==''){
                    callback(new Error(this.countryIsNull));
                } else {
                    callback();
                }
            },
            checkPhoneArea (rule,value,callback){
                if(this.form.phoneArea==''){
                    callback(new Error(this.phoneAreaIsNull));
                } else if(this.form.phone ==''){
                    callback(new Error(this.phoneIsNull));
                } else if(this.form.phone.length >15){
                    callback(new Error(this.phoneIsTooLong));
                } else {
                    callback();
                }
            },
            checkAuthCode (rule,value,callback){
                if(value==''){
                    callback(new Error(this.authCodeIsNull));
                } else {
                    callback();
                }
            },
            doSendCode() {
                this.$refs.verifyForm.validate(valid => {
                    if (valid) {
                        _self.callSendCodeApi();
                        //_self.step = 2;
                    } else {
                        if(_self.$showLog)
                        console.log('error submit!!')
                        return false
                    }
                })



            },
            callSendCodeApi(){
                var params = new Object();
                params.contactsMobile = this.form.phone;
                params.contactsCountryCode = this.form.country;
                params.mobileArea = this.form.phoneArea;
                params.key = this.emailKey;
                this.sendCodeDisabled = true
                this.axios.post('/registerct/sendsmsmessages',params).then(ret=>{
                  _self.sendCodeDisabled = false
                    if(ret.status === 1){
                        _self.step = 2;
                        _self.codeKey = ret.data.codeKey;
                        _self.doResetSendButton();
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                    console.log(ret);
                }).catch(err=>{
                  _self.sendCodeDisabled = false
                    if(_self.$showLog)
                    console.log(err);
                })
            },
            doSubmit(){
                this.$refs.authForm.validate(valid => {
                    if (valid) {
                        _self.callSubmitAuthCode();
                        //_self.step = 3;
                    } else {
                        if(_self.$showLog)
                        console.log('error submit!!')
                        return false
                    }
                })


            },
            callSubmitAuthCode(){
                var params = new Object();
                params.codeKey = this.codeKey;
                params.smsCode = this.authcode;
              _self.submitDisabled = true
                this.axios.post('/registerct/submitregister',params).then(ret=>{
                  _self.submitDisabled = false
                    if(ret.status === 1){
                        if(ret.data.joinClubInfo){
                            _self.$message.warning(this.$t(`ctError.${ret.data.joinClubInfo}`));
                        }
                        _self.step = 3;
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                    console.log(ret);
                }).catch(err=>{
                  _self.submitDisabled = false
                    if(_self.$showLog)
                    console.log(err);
                })

            },
            doResend(){
                if(this.resendText != this.defaultResendText){
                    return;
                }
                this.callSendCodeApi();
                //this.doResetSendButton();
            },
            doResetSendButton(){
                var self = this;
                this.lastTime = 60;
                this.resendText=this.lastTime + "s";
                this.timer = setInterval(function () {
                    self.lastTime --;
                    if(self.lastTime <= -1){
                        self.resendText= self.defaultResendText;
                        clearInterval(self.timer);
                    } else {
                        self.resendText=self.lastTime + "s";
                    }
                },1000)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./index.less";
</style>

<style lang="less">
    .mainwindow {
        .el-main{
            height: 100%;
        }
        .verifyForm .el-input__inner {
            height: 40px;
            line-height: 40px;
        }
        .verifyForm .el-input .el-input__clear{
            margin-top:5px;
        }
        .verifyForm .countrySelect{
            width: 320px;
        }
        .verifyForm .el-form-item__label{
            text-align: left;
            color: #666666;
            line-height: 40px;
        }
        .verifyForm .inputitem-two .el-form-item__content{
            display: flex;
            margin-left:15px !important;
        }

        .verifyForm .el-checkbox__label{
            color: #666666;
        }
        .verifyForm .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #666666;
        }
        .verifyForm .el-form-item{
            margin-bottom: 30px;
        }
        .verifycodebox .el-form-item{
            margin-bottom: 0px;
        }
        .verifycodebox .el-form-item__content{
            margin-left: 0px !important;
            display: flex;
        }
        .authencodebox .el-input{
            width: 300px;
        }

        .authencodebox .el-input__inner {
            border: 0 none;
            border-radius: 0px;
            height: 30px;
            width: 300px;
            line-height: 30px;
        }
    }



</style>
